import React, { createContext, useContext, useState } from 'react'
import ExpertModal from '../components/ExpertModal/ExpertModal'
import Portal from '../components/Portal/Portal'

const ModalContext = createContext()

export const useModal = () => {
	const context = useContext(ModalContext)
	if (!context) {
		throw new Error('useModal must be used within a ModalProvider')
	}
	return context
}

export const ModalProvider = ({ children }) => {
	const [showExpertModal, setShowExpertModal] = useState(false)

	const openExpertModal = () => setShowExpertModal(true)
	const closeExpertModal = () => setShowExpertModal(false)

	return (
		<ModalContext.Provider value={{ openExpertModal, closeExpertModal }}>
			{children}
			{showExpertModal && (
				<Portal>
					<ExpertModal onClose={closeExpertModal} />
				</Portal>
			)}
		</ModalContext.Provider>
	)
}
