import React, { useState, useRef, useEffect, useCallback } from 'react'
import {
	IoStar,
	IoFlash,
	IoClose,
	IoTrophy,
	IoRocket,
	IoSearch,
	IoShield,
	IoSparkles,
	IoPersonCircle,
	IoThumbsUp,
	IoHeart,
	IoWarning,
} from 'react-icons/io5'
import './ExpertModal.css'
import { axiosInstance } from '../../context/AuthContext'
import i18n from '../../i18n'
import debounce from 'lodash/debounce'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../context/AuthContext'

const ExpertModal = ({ onClose }) => {
	const { t } = useTranslation()
	const { user } = useAuth()
	const [currentExpertIndex, setCurrentExpertIndex] = useState(0)
	const [isDragging, setIsDragging] = useState(false)
	const [startX, setStartX] = useState(0)
	const [currentX, setCurrentX] = useState(0)
	const [isAnimating, setIsAnimating] = useState(false)
	const cardRef = useRef(null)
	const dragThreshold = 100
	const animationDuration = 300
	const [step, setStep] = useState('input')
	const [experience, setExperience] = useState('')
	const [currentLoadingMessage, setCurrentLoadingMessage] = useState(0)
	const [showCarouselHint, setShowCarouselHint] = useState(false)
	const [showReasonPopup, setShowReasonPopup] = useState(false)
	const [matchedExperts, setMatchedExperts] = useState([])
	const [matchReason, setMatchReason] = useState('')
	const [confidenceScore, setConfidenceScore] = useState(0)
	const [error, setError] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const [loadingProgress, setLoadingProgress] = useState(0)
	const [likedExperts, setLikedExperts] = useState(new Set())
	const [likeCounts, setLikeCounts] = useState({})
	const [isLiking, setIsLiking] = useState(false)

	const loadingMessages = [
		{ text: t('expertModal.loadingMessages.analyzingExperience'), icon: <IoSearch /> },
		{ text: t('expertModal.loadingMessages.findingResources'), icon: <IoRocket /> },
		{ text: t('expertModal.loadingMessages.dataSafety'), icon: <IoShield /> },
		{ text: t('expertModal.loadingMessages.matchingRecommendations'), icon: <IoSparkles /> },
	]

	const handleDragStart = e => {
		if (isAnimating) return
		const clientX = e.type.includes('mouse') ? e.clientX : e.touches[0].clientX
		setIsDragging(true)
		setStartX(clientX)
		setCurrentX(0)
		if (cardRef.current) {
			cardRef.current.classList.add('dragging')
		}
	}

	const handleDragMove = e => {
		if (!isDragging || isAnimating) return
		const touch = e.touches ? e.touches[0] : null
		const clientX = e.type.includes('mouse') ? e.clientX : touch ? touch.clientX : null
		if (clientX === null) return

		const diff = clientX - startX
		const canGoNext = currentExpertIndex < matchedExperts.length - 1
		const canGoPrev = currentExpertIndex > 0

		if ((!canGoNext && diff < 0) || (!canGoPrev && diff > 0)) {
			setCurrentX(diff * 0.2)
		} else {
			setCurrentX(diff)
		}

		if (cardRef.current) {
			cardRef.current.style.transform = `translateX(${diff}px)`
		}
	}

	const handleDragEnd = () => {
		if (!isDragging || isAnimating) return
		setIsDragging(false)

		if (cardRef.current) {
			cardRef.current.classList.remove('dragging')
		}

		const direction = currentX < 0 ? 'left' : 'right'
		const shouldSlide = Math.abs(currentX) > dragThreshold

		if (shouldSlide) {
			handleSlide(direction)
		} else {
			resetPosition()
		}
	}

	const handleSlide = direction => {
		if (isAnimating) return

		const canGoNext = currentExpertIndex < matchedExperts.length - 1
		const canGoPrev = currentExpertIndex > 0

		if ((direction === 'left' && !canGoNext) || (direction === 'right' && !canGoPrev)) {
			resetPosition()
			return
		}

		setIsAnimating(true)

		if (cardRef.current) {
			cardRef.current.classList.add(`slide-${direction}`)
		}

		setTimeout(() => {
			setCurrentExpertIndex(prev => {
				if (direction === 'left') {
					return prev + 1
				}
				return prev - 1
			})
			setIsAnimating(false)
			resetPosition()
		}, animationDuration)
	}

	const resetPosition = () => {
		if (cardRef.current) {
			cardRef.current.style.transform = 'translateX(0)'
			cardRef.current.classList.remove('slide-left', 'slide-right')
		}
		setCurrentX(0)
	}

	const getExpertMatch = async description => {
		try {
			const response = await axiosInstance.post('/experts/match', {
				description,
				language: i18n.language,
			})

			if (!response.data?.success || !response.data?.experts?.length) {
				throw new Error('No matching experts found')
			}

			// Log the structure of the first expert to see all available fields

			// Ensure each expert has an id field
			const expertsWithId = response.data.experts.map(expert => ({
				...expert,
				id: expert._id || expert.id, // Use _id if available, fallback to id
			}))

			setMatchedExperts(expertsWithId)
			setMatchReason(expertsWithId[0].matchReason)
			setConfidenceScore(expertsWithId[0].confidenceScore)
			setError(null)
			setStep('results')
		} catch (error) {
			console.error('Error getting expert match:', error.response || error)
			let errorMessage = 'Failed to find a matching expert. Please try again later.'

			if (error.response?.data?.message) {
				errorMessage = error.response.data.message
			} else if (error.message === 'No matching experts found') {
				errorMessage = 'No experts available matching your criteria. Please try again later.'
			}

			setError(errorMessage)
			setStep('input')
		}
	}

	const getExpertMatchWithRetry = async (description, retries = 3, delay = 1000) => {
		try {
			setIsLoading(true)
			await getExpertMatch(description)
		} catch (error) {
			if (retries > 0) {
				await new Promise(resolve => setTimeout(resolve, delay))
				return getExpertMatchWithRetry(description, retries - 1, delay * 1.5)
			} else {
				setError('Unable to connect to expert matching service. Please try again later.')
				setStep('input')
			}
		} finally {
			setIsLoading(false)
		}
	}

	const handleSubmit = async e => {
		e.preventDefault()
		if (!experience.trim()) return

		setStep('loading')
		setIsLoading(true)

		try {
			// Show loading messages with delay
			let messageIndex = 0
			const messageInterval = setInterval(() => {
				if (messageIndex < loadingMessages.length - 1) {
					messageIndex++
					setCurrentLoadingMessage(messageIndex)
				}
			}, 1500)

			// Get expert match
			await getExpertMatchWithRetry(experience)

			// Clear interval and proceed to results
			clearInterval(messageInterval)
			setStep('results')
			setShowCarouselHint(true)
		} catch (error) {
			setError(error.message)
			setStep('input')
		} finally {
			setIsLoading(false)
		}
	}

	const handleKeyDown = e => {
		if (e.key === 'Enter' && !e.shiftKey) {
			e.preventDefault()
			handleSubmit(e)
		}
	}

	useEffect(() => {
		const handleKeyDown = e => {
			if (isAnimating) return

			if (e.key === 'ArrowLeft' && currentExpertIndex > 0) {
				handleSlide('right')
			} else if (e.key === 'ArrowRight' && currentExpertIndex < matchedExperts.length - 1) {
				handleSlide('left')
			}
		}

		window.addEventListener('keydown', handleKeyDown)
		return () => window.removeEventListener('keydown', handleKeyDown)
	}, [currentExpertIndex, isAnimating, matchedExperts.length])

	useEffect(() => {
		if (matchedExperts.length > 0) {
			const counts = {}
			matchedExperts.forEach(expert => {
				counts[expert.id] = expert.likeCount || 0
			})
			setLikeCounts(counts)
		}
	}, [matchedExperts])

	useEffect(() => {
		const loadLikeStatus = async () => {
			if (!user || !matchedExperts[currentExpertIndex]) return

			try {
				const response = await axiosInstance.get(`/experts/like-status/${matchedExperts[currentExpertIndex]._id}`)

				if (!response.data) {
					throw new Error('Failed to load like status')
				}

				const { hasLiked, likeCount } = response.data

				if (hasLiked) {
					setLikedExperts(prev => new Set(prev).add(matchedExperts[currentExpertIndex]._id))
				}

				setLikeCounts(prev => ({
					...prev,
					[matchedExperts[currentExpertIndex]._id]: likeCount,
				}))
			} catch (error) {
				console.error('Error loading like status:', error)
			}
		}

		loadLikeStatus()
	}, [currentExpertIndex, matchedExperts, user])

	const handleLike = async () => {
		console.log('handleLike called')
		if (!user || isLiking) {
			console.log('Early return - user or isLiking:', { user, isLiking })
			return
		}

		const currentExpert = matchedExperts[currentExpertIndex]
		console.log('Current expert:', currentExpert)

		if (!currentExpert || !currentExpert._id) {
			console.log('Early return - no expert or expert ID')
			return
		}

		setIsLiking(true)

		try {
			const response = await axiosInstance.post('/experts/like', {
				expertId: currentExpert._id,
			})

			if (response.data.success) {
				// Update local state
				setLikedExperts(prev => {
					const newSet = new Set(prev)
					if (response.data.action === 'like') {
						newSet.add(currentExpert._id)
					} else {
						newSet.delete(currentExpert._id)
					}
					return newSet
				})

				// Update like counts
				setLikeCounts(prev => {
					const newCounts = {
						...prev,
						[currentExpert._id]: response.data.likeCount,
					}
					return newCounts
				})
			}
		} catch (error) {
			console.error('Error updating like:', error.response || error)
		} finally {
			setIsLiking(false)
		}
	}

	const handleContactClick = async expertId => {
		try {
			// Track the click
			await axiosInstance.post('/experts/click', {
				expertId: expertId,
			})

			// Open the redirect link in a new tab
			window.open(matchedExperts[currentExpertIndex].redirectLink, '_blank')
		} catch (error) {
			console.error('Error updating click count:', error)
			// Still open the link even if tracking fails
			window.open(matchedExperts[currentExpertIndex].redirectLink, '_blank')
		}
	}

	const renderContent = () => {
		switch (step) {
			case 'input':
				return (
					<div className="input-section">
						<div className="beta-badge">{t('expertModal.beta')}</div>
						<div className="no-experts-banner">
							<IoWarning className="no-experts-icon" />
							<span>{t('expertModal.noExpertsAvailable')}</span>
						</div>
						<div className="input-header">
							<h2>{t('expertModal.shareYourStory')}</h2>
							<p>{t('expertModal.storyDescription')}</p>
						</div>
						<form onSubmit={handleSubmit} className="input-form-expert__modal">
							<div className="input-container">
								<textarea
									className="experience-input"
									value={experience}
									onChange={e => setExperience(e.target.value)}
									onKeyDown={handleKeyDown}
									placeholder={t('expertModal.experiencePlaceholder')}
									required
									disabled
								/>
								<div className="input-footer">
									<span className="input-hint">{t('expertModal.pressEnterToSubmit')}</span>
									<button type="submit" className="submit-button" disabled>
										{t('expertModal.findPerfectMatch')}
									</button>
								</div>
							</div>
						</form>
					</div>
				)

			case 'loading':
				return (
					<div className="loading-state">
						<div className="loading-content">
							<div className="loading-icon-wrapper">
								<div className="loading-icon" />
							</div>
							<div className="loading-messages-container">
								<div className={`loading-message visible`}>
									{loadingMessages[currentLoadingMessage].icon}
									<span>{loadingMessages[currentLoadingMessage].text}</span>
								</div>
							</div>
							<div className="loading-progress">
								{loadingMessages.map((_, index) => (
									<div key={index} className={`progress-dot ${index === currentLoadingMessage ? 'active' : ''}`} />
								))}
							</div>
						</div>
					</div>
				)

			case 'results':
				return (
					<div className="experts-carousel">
						{showCarouselHint && matchedExperts.length > 1 && (
							<div className="carousel-hint-overlay" onClick={() => setShowCarouselHint(false)}>
								<div className="carousel-hint-content" onClick={e => e.stopPropagation()}>
									<div className="hint-phone-animation">
										<div className="hint-phone-frame">
											<div className="hint-phone-content" />
											<div className="swipe-indicator">
												<div className="swipe-arrow" />
											</div>
										</div>
									</div>
									<div className="hint-text">{t('expertModal.carouselHint.swipeText')}</div>
									<div className="hint-dismiss-text">{t('expertModal.carouselHint.dismissText')}</div>
								</div>
							</div>
						)}
						{matchedExperts.length === 0 ? (
							<div className="no-results">
								<p>Nie znaleziono ekspertów spełniających Twoje kryteria</p>
							</div>
						) : (
							<div
								ref={cardRef}
								className="expert__card"
								onMouseDown={handleDragStart}
								onMouseMove={handleDragMove}
								onMouseUp={handleDragEnd}
								onMouseLeave={handleDragEnd}
								onTouchStart={handleDragStart}
								onTouchMove={handleDragMove}
								onTouchEnd={handleDragEnd}
								style={{ touchAction: 'pan-y pinch-zoom' }}>
								<div className="expert__badge">
									<IoTrophy className="badge-icon" />
									<span className="badge-text">
										{t('expertModal.expertCard.matchLabel', {
											number: currentExpertIndex + 1,
											total: matchedExperts.length,
											score: Math.round(matchedExperts[currentExpertIndex].confidenceScore * 100),
										})}
									</span>
								</div>
								<div className="expert__card__top">
									<div className="expert__card__top__left">
										<img
											src={matchedExperts[currentExpertIndex].image}
											alt={matchedExperts[currentExpertIndex].title}
										/>
									</div>
									<div className="expert__card__top__right">
										<div className="expert__header">
											<span className="expert__type">
												{matchedExperts[currentExpertIndex].serviceType?.charAt(0).toUpperCase() +
													matchedExperts[currentExpertIndex].serviceType?.slice(1)}
											</span>
											<div className="reviews-item">
												<button
													className={`like-button ${
														likedExperts.has(matchedExperts[currentExpertIndex]._id) ? 'liked' : ''
													}`}
													onClick={handleLike}
													aria-label="Like expert profile">
													<IoHeart className="like-icon" />
													<span className="like-count">{likeCounts[matchedExperts[currentExpertIndex]._id] || 0}</span>
												</button>
											</div>
										</div>
										<h1>
											{['psychologist', 'coaching'].includes(matchedExperts[currentExpertIndex].serviceType)
												? matchedExperts[currentExpertIndex].name
												: matchedExperts[currentExpertIndex].contentTitle}
										</h1>
										<h4>
											{['psychologist', 'coaching'].includes(matchedExperts[currentExpertIndex].serviceType)
												? `${matchedExperts[currentExpertIndex].experience} years of experience`
												: matchedExperts[currentExpertIndex].name}
										</h4>
									</div>
								</div>
								<div className="expert__card__bottom">
									<div className="expert__card__bottom__left">
										<div className="description-section">
											<h3>{t('expertModal.expertCard.about')}</h3>
											<p>{matchedExperts[currentExpertIndex].description}</p>
										</div>
										<div className="categories-container">
											{matchedExperts[currentExpertIndex].specializations.map((specialization, index) => (
												<React.Fragment key={index}>
													<div className="category-item">
														<IoFlash className="category-icon" />
														{specialization}
													</div>
													{index < matchedExperts[currentExpertIndex].specializations.length - 1 && (
														<span className="category-splitter-dot">•</span>
													)}
												</React.Fragment>
											))}
										</div>
									</div>
									<div className="expert-buttons">
										<button
											className="expert-button"
											onClick={() => handleContactClick(matchedExperts[currentExpertIndex].id)}
											title={matchedExperts[currentExpertIndex].redirectLink || t('expert.noRedirectLink')}>
											{t('expertModal.expertCard.contactExpert')}
										</button>
										<button
											className="expert-button-secondary"
											onClick={() => setShowReasonPopup(true)}
											disabled={currentExpertIndex !== 0}>
											{t('expertModal.expertCard.whyThisExpert')}
											{currentExpertIndex !== 0 && (
												<span className="tooltip">{t('expertModal.expertCard.whyThisExpertTooltip')}</span>
											)}
										</button>
									</div>
								</div>
							</div>
						)}
					</div>
				)
		}
	}

	const ExpertReasonPopup = React.memo(({ expert, onClose }) => {
		const handleContentClick = useCallback(e => {
			e.stopPropagation()
		}, [])

		return (
			<div className="expert-reason-overlay" onClick={onClose}>
				<div className="expert-reason-popup" onClick={handleContentClick}>
					<button className="expert-reason-close" onClick={onClose}>
						<IoClose />
					</button>

					<div className="expert-reason-content">
						<div className="expert-reason-section">
							<h3 className="expert-reason-title">
								<IoPersonCircle className="reason-icon" />
								{t('expertModal.reasonPopup.aboutExpert')}
							</h3>
							<p className="expert-reason-text">
								{t('expertModal.reasonPopup.expertDescription', {
									name: expert.title,
									type: expert.serviceType.toLowerCase(),
									experience: expert.experience,
									specializations: expert.specializations.join(', '),
									rating: expert.rating,
									reviews: expert.reviewsCount,
								})}
							</p>
						</div>

						<div className="expert-reason-divider" />

						<div className="expert-reason-section">
							<h3 className="expert-reason-title">
								<IoThumbsUp className="reason-icon" />
								{t('expertModal.reasonPopup.whyThisExpert')}
							</h3>
							<p className="expert-reason-text">{matchReason}</p>
						</div>
					</div>
				</div>
			</div>
		)
	})

	ExpertReasonPopup.displayName = 'ExpertReasonPopup'

	const handleOverlayClick = e => {
		if (e.target.className === 'expert-modal-overlay') {
			onClose()
		}
	}

	return (
		<div className="expert-modal-overlay" onClick={handleOverlayClick}>
			<button className="expert-modal-close" onClick={onClose}>
				<IoClose />
			</button>
			<div className="expert-modal">
				{error && (
					<div className="error-message-box">
						<p>{t('expertModal.error')}</p>
					</div>
				)}
				{renderContent()}
			</div>
			{showReasonPopup && matchedExperts.length > 0 && (
				<ExpertReasonPopup expert={matchedExperts[currentExpertIndex]} onClose={() => setShowReasonPopup(false)} />
			)}
		</div>
	)
}

export default ExpertModal
