import { useEffect } from 'react'
import { createPortal } from 'react-dom'

const Portal = ({ children }) => {
	// Create a div element for the portal
	const mount = document.getElementById('portal-root')
	const el = document.createElement('div')

	useEffect(() => {
		// Append the element to the DOM when the component mounts
		mount.appendChild(el)
		// Remove the element from the DOM when the component unmounts
		return () => mount.removeChild(el)
	}, [el, mount])

	// Use createPortal to render children into the element
	return createPortal(children, el)
}

export default Portal
